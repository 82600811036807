<template>
    <div class="info_screen content-grid">
        <div class="info_screen__content">
            <div class="info_sceen__desc">
                <p v-html="$t('project.p_1')"/>

                <p v-html="$t('project.p_2')"/>

                <p v-html="$t('project.p_3')"/>

                <p v-html="$t('project.p_4')"/> 
            </div>
            <div class="info_screen__works">
                <div class="works__item" v-for="(item, index) in items" :key="index">
                    {{ $t(item.text) }}
                </div>
            </div>
            <MainButton type="main" :text="$t('project.btn')" />
            <div class="info_screen__desc">
                <p v-html="$t('project.friends')"></p>
            </div>
            <div class="info_screen__partners">
                <div class="partners__item" v-for="(item, index) in partners" :key="index+'parnter'">
                    <img :src="item.src" :alt="item.alt">
                    <a :href="item.link" target="_blank" v-if="item.link"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainButton from '@/components/elements/Button'

export default {
    components: {
        MainButton
    },
    data() {
        return {
            items: [
                {text: 'project.works.w_1'},
                {text: 'project.works.w_2'},
                {text: 'project.works.w_3'},
                {text: 'project.works.w_4'},
                {text: 'project.works.w_5'},
                {text: 'project.works.w_6'},
                {text: 'project.works.w_7'},
                {text: 'project.works.w_8'},
            ],
            partners: [
                {src: this.$router.options.base + 'media/project/partner1.svg', alt: 'partner-1'},
                {src: this.$router.options.base + 'media/project/partner2.svg', alt: 'partner-2', link: 'https://houseofeurope.org.ua/'}
            ]
        }
    }
}
</script>


<style scoped lang="scss">
.info_screen {
    display: flex;
    align-items: center;
    padding-top: 132px;
    padding-bottom: 132px;
    @media(max-width: 768px) {
        padding-top: 57px;
        padding-bottom: 84px;
    }
    &__content {
        width: 1322px;
        max-width: 100%;
    }
    &__desc {
        font-size: 24px;
        line-height: 2;
        letter-spacing: 0.49px;
        color: #000;
    }
    p {
        display: block;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 2.2;
        letter-spacing: 0.41px;
        @media(max-width: 768px) {
            font-size: 16px;
            line-height: 2.25;
            letter-spacing: 0.33px;
        }
    }
    &__works {
        margin-top: 62px;
        @media(max-width: 768px) {
            margin-top: 53px;
        }
    }
    &__partners {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 59px;
          @media(max-width: 768px) {
              margin-top: 64px;
              flex-direction: column;
              justify-content: center;
        }
    }
}
.works {
    &__item {
        margin-bottom: 62px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.41px;
        color: #000;
        cursor: pointer;
        @media(max-width: 768px) {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.33px;
            margin-bottom: 36px;
        }
    }
}
.partners {
    &__item {
        position: relative;
        margin-right: 67px;
        @media(max-width: 768px) {
            margin-right: 0;
            &:first-of-type {
                margin-bottom: 57px;
            }
        }
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
::v-deep {
    .btn_main {
        width: 318px;
        margin-bottom: 62px;
        @media(max-width: 768px) {
            width: 265px;
            display: block;
            margin: 57px auto 57px;
        }
    }
   
}
</style>