<template>
    <div>
        <MainScreen/>
        <InfoScreen/>
    </div>
</template>

<script>
import MainScreen from '@/components/project/MainScreen'
import InfoScreen from '@/components/project/InfoScreen'

export default {
    components: {
        MainScreen,
        InfoScreen
    }
}
</script>

<style scoped lang="scss">

</style>
