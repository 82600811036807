<template>
    <div style="position: relative;">
        <div class="main_screen content-grid">
            <div class="main_screen__content">
                <h1 class="main_screen__title" v-html="$t('project.title')"></h1>
                <!-- <span class="main_screen__desc">{{$t('project.desc')}}</span> -->
                <MainButton :text="$t('project.btn')" type="main"/>
            </div>
        </div>
        <vueper-slides autoplay>
            <vueper-slide v-for="(item, i) in items" :key="i" :content="item.content"></vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
import MainButton from '@/components/elements/Button.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    components: {
       VueperSlides, VueperSlide,
       MainButton
    },
    data() {
        return {
            items: [
                {content: '<img class="slider__img" src="/media/project/slider/slider_1.jpg">'}, 
                {content: '<img class="slider__img" src="/media/project/slider/slider_2.jpg">'}, 
                {content: '<img class="slider__img" src="/media/project/slider/slider_3.jpg">'}, 
                {content: '<img class="slider__img" src="/media/project/slider/slider_4.jpg">'}, 
                {content: '<img class="slider__img" src="/media/project/slider/slider_5.jpg">'}, 
                {content: '<img class="slider__img" src="/media/project/slider/slider_6.jpg">'},
            ],
            settings: {
                "dots": true,
                // "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": false,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.main_screen {
    display: flex;
    align-items: center;
    height: 100vh;
    max-width: 100%;
    &__content {
        max-width: 100%;
        position: relative;
        z-index: 11;
    }
    &__title {
        font-family: 'Oswald', sans-serif;
        font-size: 70px;
        font-weight: 500;
        line-height: 1.43;
        letter-spacing: 1.44px;
        color: #fff;
        text-transform: uppercase;
        max-width: 1400px;
    }
    &__desc {
        display: block;
        margin: 36px 0;
        font-weight: 500;
        letter-spacing: 0.85px;
        color: #f5f6fa;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.78px;
    }
    @media(max-width: 768px) {
        height: 524px;
        &__title {
            font-size: 30px;
            line-height: 1.5;
        }
        &__desc {
            font-size: 16px;
            line-height: 1.88;
            margin: 40px 0;
        }
        ::v-deep {
            .btn_main {
                width: 265px;
                margin: 0 auto;
                margin-top: 40px;
                display: block;
            }
        }
    }
}
::v-deep {
    .btn_main {
        width: 318px;
        margin-top: 36px;
    }
}
</style>

<style lang="scss">
.vueperslide {
  &__title {
    font-size: 7em;
    opacity: 0.6;
  }
}
.vueperslides__arrow {
    display: none;
}
.slider__img {
    width: 100%;
}
.vueperslides {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.vueperslides__parallax-wrapper, .vueperslides__inner {
    height: 100%;
}
.vueperslides__bullet {
    margin: 0 4px;
}
.vueperslides__bullets {
    margin-bottom: 44px;
    @media(max-width: 768px) {
        display: none;
    }
}
</style>